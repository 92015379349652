/* DashboardPage.css */

/* Base Layout */
.dashboard-wrapper {
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  padding: 0 0 20px;
}

/* Content Sections */
.dashboard-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.season-overview,
.player-overview,
.position-details {
  width: 100%;
}

/* Table Containers */
.dashboard-table-container {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto 20px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.dashboard-table-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 10px;
}

/* Table Columns */
.dashboard-rank-column {
  width: 15%;
  min-width: 50px;
}

.dashboard-team-column {
  width: 65%;
  min-width: 150px;
}

.dashboard-points-column {
  width: 20%;
  min-width: 60px;
}

table td.dashboard-rank-change-column {
  width: 20px;
  text-align: center;
  padding: 0 !important;
  white-space: nowrap;
}

/* If that alone doesn't work, we can be more specific: */
td.dashboard-rank-change-column {
  list-style: none;
  padding: 0;
  list-style-type: none;
}

/* Table Styles */
.dashboard-table-container .table td,
.dashboard-table-container .table th {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dashboard-table-container .table {
  width: 100%;
  table-layout: fixed;
}

.dashboard-table-container .table thead th {
  background-color: var(--bs-primary);
  color: var(--bs-white);
  border-color: var(--bs-primary);
}

[data-bs-theme="dark"] .dashboard-table-container .table thead th {
  background-color: var(--bs-primary-dark);
  color: var(--bs-white);
  border-color: var(--bs-primary-dark);
}

/* Tab Navigation */
.nav-tabs {
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
}

.nav-tabs .nav-item {
  float: none;
  display: inline-block;
}

/* Responsive Styles */
@media screen and (max-width: 1024px) {
  .dashboard-table-container {
    padding: 0 10px;
  }
  
  .dashboard-table-title {
    font-size: 0.9rem;
  }
  
  .dashboard-rank-column {
    min-width: 40px;
  }
  
  .dashboard-team-column {
    min-width: 120px;
  }
  
  .dashboard-points-column {
    min-width: 50px;
  }
}

/* Small Screen Adjustments */
@media screen and (max-width: 360px) {
  .container-fluid {
    padding-left: 5px;
    padding-right: 5px;
  }
}