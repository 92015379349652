/* Base Layout */
.player-points-wrapper {
    width: 100%;
    max-width: 100vw;
    overflow-x: hidden;
    padding: 0 0 20px;
  }
  
  /* Content Sections */
  .player-points-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }
  
  /* Form Container */
  .player-points-form-container {
    width: 100%;
    margin-bottom: 1rem;
  }
  
  /* Table Containers */
  .player-points-table-container {
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  
  /* Table Styles */
  .player-points-table-container .table {
    width: 100%;
    table-layout: fixed;
    margin-bottom: 0;
    color: black;
  }
  
  .player-points-table-container .table td,
  .player-points-table-container .table th {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  /* Light mode text color */
  [data-bs-theme="light"] .player-points-table-container .table td {
    color: black;
  }
  
  /* Dark mode text color */
  [data-bs-theme="dark"] .player-points-table-container .table td {
    color: white;
  }
  
  /* Header styling */
  .player-points-table-container .table thead th {
    background-color: var(--bs-primary);
    color: var(--bs-white);
    border-color: var(--bs-primary);
    cursor: pointer;
  }
  
  [data-bs-theme="dark"] .player-points-table-container .table thead th {
    background-color: var(--bs-primary-dark);
    color: var(--bs-white);
    border-color: var(--bs-primary-dark);
  }
  
  /* Column Widths */
  .player-name-column {
    width: 25%;
    min-width: 200px;
  }
  
  .player-position-column {
    width: 10%;
    min-width: 80px;
  }
  
  .player-team-column {
    width: 15%;
    min-width: 80px;
  }
  
  .player-points-column {
    width: 20%;
    min-width: 100px;
  }
  
  .player-tuff-team-column {
    width: 30%;
    min-width: 150px;
  }
  
  /* Responsive Styles */
  @media screen and (max-width: 1024px) {
    .player-points-table-container {
      padding: 0 10px;
    }
  
    .player-name-column {
      min-width: 160px;
    }
  
    .player-position-column {
      min-width: 60px;
    }
  
    .player-team-column {
      min-width: 60px;
    }
  
    .player-points-column {
      min-width: 80px;
    }
  
    .player-tuff-team-column {
      min-width: 120px;
    }
  }
  
  /* Small Screen Adjustments */
  @media screen and (max-width: 360px) {
    .container-fluid {
      padding-left: 5px;
      padding-right: 5px;
    }
  }