/* General styles */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* App-wide styles */
.App {
  text-align: center;
}

/* Theme styles */
#light {
  background-color: white;
}

#dark {
  background-color: black;
}

#light .App {
  background-color: white;
  color: black;
}

#dark .App {
  background-color: black;
  color: white;
}

#dark .form-check-label {
  color: white;
}

/* Footer styles */
#light .footer, #dark .footer {
  font-size: calc(10px + 2vmin);
  position: fixed;
  width: 100%;
  bottom: 0;
}

#light .footer {
  background-color: white;
  border-top: 10px solid white;
  border-bottom: 10px solid white;
  color: black;
}

#dark .footer {
  background-color: black;
  border-top: 10px solid black;
  border-bottom: 10px solid black;
  color: white;
}

/* Utility classes */
.skeleton-loader {
  padding: 1rem;
}

.skeleton-line {
  height: 20px;
  margin-bottom: 10px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% { background-position: 200% 0; }
  100% { background-position: -200% 0; }
}

/* Responsive utility classes */
.mobile-only { display: table-cell; }
.desktop-only { display: none; }

@media screen and (min-width: 768px) {
  .mobile-only { display: none; }
  .desktop-only { display: table-cell; }
}