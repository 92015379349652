.home-page .page-layout__content {
  margin: 0 !important;
  padding: 0 !important;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Prevent scrolling on homepage */
}

.Home-header {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  background-color: #f0f0f0; /* Fallback color */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* Adjust for the footer */
  padding-bottom: var(--footer-height);
  box-sizing: border-box;
}

.home-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* Adjust height to account for footer */
  height: calc(100% - var(--footer-height));
  padding: 0.5rem;
  box-sizing: border-box;
}

.home-page-card {
  width: 100%;
  max-width: 90%;
  margin: 0 auto;
  padding: 0.5rem;
  box-sizing: border-box;
}

@media (min-width: 576px) {
  .home-page-card {
    max-width: 18rem;
  }
}

@media (min-width: 768px) {
  .home-page-card {
    max-width: 22rem;
  }
}