/* Base Layout */
.tuff-standings-wrapper {
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  padding: 0 0 20px;
}

/* Table Container */
.tuff-standings-wrapper .StyledTable {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto 20px;
}

/* Table Styles */
.tuff-standings-wrapper .StyledTable {
  width: 100%;
  table-layout: fixed;
}

/* Set default text colors based on theme */
.tuff-standings-wrapper .StyledTable td,
.tuff-standings-wrapper .StyledTable th {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--bs-body-color);
}

/* Header styling */
.tuff-standings-wrapper .StyledTable thead th {
  background-color: var(--bs-primary);
  color: var(--bs-white) !important;
  border-color: var(--bs-primary);
}

[data-bs-theme="dark"] .tuff-standings-wrapper .StyledTable thead th {
  background-color: var(--bs-primary-dark);
  color: var(--bs-white) !important;
  border-color: var(--bs-primary-dark);
}

/* Theme-specific text colors for table cells */
.tuff-standings-wrapper .StyledTable {
  color: var(--bs-body-color);
}

/* Ensure links in table inherit the correct text color */
.tuff-standings-wrapper .StyledTable a {
  color: inherit;
}

/* Column Widths - with font-relative sizing */
.dashboard-rank-column {
  width: 15%;
  min-width: 3.5rem;
}

.dashboard-rank-change-column {
  width: 20px;
  text-align: center;
}

.dashboard-team-column {
  width: 65%;
  min-width: 9rem;
}

.dashboard-points-column {
  width: 20%;
  min-width: 4rem;
}

.dashboard-week-column {
  width: 5rem;
  min-width: 5rem;
  max-width: 5rem;
}

/* Color styles for both desktop and mobile */
.tuff-standings-wrapper .StyledTable td.custom-bg.primary,
.tuff-standings-wrapper .mobile-week-points.custom-bg.primary {
  background-color: #007bff !important;
  color: white !important;
}

.tuff-standings-wrapper .StyledTable td.custom-bg.warning,
.tuff-standings-wrapper .mobile-week-points.custom-bg.warning {
  background-color: #ffc107 !important;
  color: black !important;
}

.tuff-standings-wrapper .StyledTable td.custom-bg.danger,
.tuff-standings-wrapper .mobile-week-points.custom-bg.danger {
  background-color: #dc3545 !important;
  color: white !important;
}

.tuff-standings-wrapper .StyledTable td.custom-bg.info,
.tuff-standings-wrapper .mobile-week-points.custom-bg.info {
  background-color: #17a2b8 !important;
  color: black !important;
}

.tuff-standings-wrapper .StyledTable td.custom-bg.success,
.tuff-standings-wrapper .mobile-week-points.custom-bg.success {
  background-color: #28a745 !important;
  color: white !important;
}

/* Table Responsiveness */
.tuff-standings-wrapper .table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

/* Title styles */
.tuff-standings-wrapper .dashboard-table-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 10px;
}

/* Responsive styles */
@media screen and (max-width: 767px) {
  .tuff-standings-wrapper .StyledTable {
    font-size: 0.9rem;
  }

  .desktop-only {
    display: none;
  }

  .mobile-only {
    display: table-cell;
  }

  .mobile-week-points {
    padding: 0.25rem;
    border-radius: 0.25rem;
    display: inline-block;
    width: 100%;
    text-align: center;
  }

  /* Ensure mobile colors stay visible */
  .tuff-standings-wrapper .mobile-week-points.custom-bg {
    display: inline-block;
    width: 100%;
  }

  .dashboard-week-column {
    width: 4rem;
    min-width: 4rem;
    max-width: 4rem;
  }
}

@media screen and (min-width: 768px) {
  .mobile-only {
    display: none;
  }

  .desktop-only {
    display: table-cell;
  }
}

@media screen and (min-width: 1200px) {
  .dashboard-week-column {
    width: 6rem;
    min-width: 6rem;
    max-width: 6rem;
  }
}

@media (max-width: 767px) {
  .week-toggle-container {
    display: none !important;
  }
}

/* Legend styles */
.tuff-standings-wrapper .legend-container {
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.tuff-standings-wrapper .legend-item {
  display: flex;
  align-items: center;
  margin: 0 10px;
}

.tuff-standings-wrapper .legend-color {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  border: 1px solid var(--bs-border-color);
}