.page-layout {
  text-align: center;
}

#dark .page-layout__content {
  overflow: auto;
  background-color: black;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  
  font-size: calc(10px + 2vmin);
  color: white;
}

#light .page-layout__content {
  overflow: auto;
  background-color: white;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  
  font-size: calc(10px + 2vmin);
  color: black;
}

@media only screen and (max-width: 640px) {
  .page-layout__content {
    margin-top: 0;
  }
}

