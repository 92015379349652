/* Base Layout */
.fantasy-points-wrapper {
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  padding: 0 0 20px;
}

/* Content Sections */
.fantasy-points-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

/* Form Container */
.fantasy-points-form-container {
  width: 100%;
  margin-bottom: 1rem;
}

/* Table Containers */
.fantasy-points-table-container {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

/* Table Styles */
.fantasy-points-table-container .table {
  width: 100%;
  table-layout: fixed;
  margin-bottom: 0;
  color: black;
}

.fantasy-points-table-container .table td,
.fantasy-points-table-container .table th {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Light mode text color */
[data-bs-theme="light"] .fantasy-points-table-container .table td {
  color: black;
}

/* Dark mode text color */
[data-bs-theme="dark"] .fantasy-points-table-container .table td {
  color: white;
}

/* Header styling */
.fantasy-points-table-container .table thead th {
  background-color: var(--bs-primary);
  color: var(--bs-white);
  border-color: var(--bs-primary);
}

[data-bs-theme="dark"] .fantasy-points-table-container .table thead th {
  background-color: var(--bs-primary-dark);
  color: var(--bs-white);
  border-color: var(--bs-primary-dark);
}

/* Column Widths */
.fantasy-position-column {
  width: 15%;
  min-width: 80px;
}

.fantasy-player-column {
  width: 45%;
  min-width: 200px;
}

.fantasy-team-column {
  width: 15%;
  min-width: 80px;
}

.fantasy-points-column {
  width: 25%;
  min-width: 100px;
}

/* Total Points container */
.fantasy-points-total {
  margin-top: 1rem;
  font-size: 1.2rem;
}

/* Light mode total points */
[data-bs-theme="light"] .fantasy-points-total {
  color: black;
}

/* Dark mode total points */
[data-bs-theme="dark"] .fantasy-points-total {
  color: white;
}

/* Responsive Styles */
@media screen and (max-width: 1024px) {
  .fantasy-points-table-container {
    padding: 0 10px;
  }

  .fantasy-position-column {
    min-width: 60px;
  }

  .fantasy-player-column {
    min-width: 160px;
  }

  .fantasy-team-column {
    min-width: 60px;
  }

  .fantasy-points-column {
    min-width: 80px;
  }
}

/* Small Screen Adjustments */
@media screen and (max-width: 360px) {
  .container-fluid {
    padding-left: 5px;
    padding-right: 5px;
  }
}